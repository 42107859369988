//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import BaseLayout from "@/layouts/Base";
export default {
  name: "refund-page",
  components: {
    BaseLayout,
  },
  metaInfo: {
    title: "Refunds",
  },
  data() {
    return {};
  },
  mounted() {},
  methods: {},
};
